// Generated by Framer (aab6bf9)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Embed from "https://framerusercontent.com/modules/o1PI5S8YtkA5bP5g4dFz/Mn69eMnaMcqJ65TjxwR1/Embed.js";
import ContactDesktop from "./T0N40uEXp";
const EmbedFonts = getFonts(Embed);
const ContactDesktopFonts = getFonts(ContactDesktop);

const cycleOrder = ["gA7Zz4PYO", "t8CyO6fMj"];

const serializationHash = "framer-7oQIq"

const variantClassNames = {gA7Zz4PYO: "framer-v-1s9efkn", t8CyO6fMj: "framer-v-17qu0uo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "gA7Zz4PYO", "Variant 2": "t8CyO6fMj"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gA7Zz4PYO"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "gA7Zz4PYO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1s9efkn", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"gA7Zz4PYO"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({t8CyO6fMj: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-9sknh5"} data-border layoutDependency={layoutDependency} layoutId={"ljrSl24J4"} style={{"--border-bottom-width": "0.25px", "--border-color": "rgb(8, 20, 158)", "--border-left-width": "0.25px", "--border-right-width": "0.25px", "--border-style": "solid", "--border-top-width": "0.25px", backgroundColor: "rgba(255, 255, 255, 0.95)", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "0px 1px 3px 3px rgba(245, 118, 7, 0.25)"}}><ComponentViewportProvider ><motion.div className={"framer-1vgbsos-container"} layoutDependency={layoutDependency} layoutId={"Cort0Rigy-container"}><Embed height={"100%"} html={""} id={"Cort0Rigy"} layoutId={"Cort0Rigy"} style={{height: "100%", width: "100%"}} type={"url"} url={"https://kaushaltax.in/Contact/"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider width={"413px"} {...addPropertyOverrides({t8CyO6fMj: {width: "270px"}}, baseVariant, gestureVariant)}><motion.div className={"framer-imfdc7-container"} layoutDependency={layoutDependency} layoutId={"EmGTqH9vm-container"}><ContactDesktop height={"100%"} id={"EmGTqH9vm"} layoutId={"EmGTqH9vm"} style={{height: "100%", width: "100%"}} variant={"bdlb9ui5s"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7oQIq.framer-1cbyjhi, .framer-7oQIq .framer-1cbyjhi { display: block; }", ".framer-7oQIq.framer-1s9efkn { height: 712px; overflow: hidden; position: relative; width: 800px; }", ".framer-7oQIq .framer-9sknh5 { bottom: 0px; flex: none; height: 679px; left: 0px; overflow: hidden; position: absolute; right: 0px; will-change: var(--framer-will-change-override, transform); }", ".framer-7oQIq .framer-1vgbsos-container { bottom: -65px; flex: none; left: -17px; position: absolute; right: -15px; top: 0px; }", ".framer-7oQIq .framer-imfdc7-container { flex: none; height: 76px; left: calc(51.12500000000002% - 413px / 2); position: absolute; top: 0px; width: 413px; z-index: 1; }", ".framer-7oQIq.framer-v-17qu0uo.framer-1s9efkn { height: 1230px; width: 570px; }", ".framer-7oQIq.framer-v-17qu0uo .framer-9sknh5 { bottom: unset; height: 1114px; top: 0px; }", ".framer-7oQIq.framer-v-17qu0uo .framer-1vgbsos-container { bottom: -14px; height: 887px; left: -6px; right: -5px; top: unset; }", ".framer-7oQIq.framer-v-17qu0uo .framer-imfdc7-container { height: 52px; left: calc(46.49122807017546% - 270px / 2); top: 102px; width: 270px; }", ".framer-7oQIq[data-border=\"true\"]::after, .framer-7oQIq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 712
 * @framerIntrinsicWidth 800
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"t8CyO6fMj":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKWRDM82BJ: React.ComponentType<Props> = withCSS(Component, css, "framer-7oQIq") as typeof Component;
export default FramerKWRDM82BJ;

FramerKWRDM82BJ.displayName = "Contact Hover";

FramerKWRDM82BJ.defaultProps = {height: 712, width: 800};

addPropertyControls(FramerKWRDM82BJ, {variant: {options: ["gA7Zz4PYO", "t8CyO6fMj"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerKWRDM82BJ, [{explicitInter: true, fonts: []}, ...EmbedFonts, ...ContactDesktopFonts], {supportsExplicitInterCodegen: true})